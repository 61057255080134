import { createSlice } from "@reduxjs/toolkit";
import { filterMissingFields, getCountryCurrencySymbol, getDateString, getLapseRisk, getNameString, nBuilder, nFormatter } from "../../global/helpers";
import { defaultChartColors, defaultColumnsAnnuities, defaultColumnsBroker, defaultColumnsLife, defaultColumnsNewLapse, priorityMaping, sortKeysAnnuities, sortKeysBroker, sortKeysRunLife, sortKeysRunNewLapse, usStatesData } from "../../global/constants";
import moment from "moment";

const priority = ['high','low', 'mid', 'low', 'mid', 'high', 'low']

const initialState = {
    groupByProject: "All",
    projectLabels: [],
    runsMeta: [],
    projectIds: [],
    impactsData: {
     original: null,
     potential: null,
     total: null,
     lapse: {},
     ui: {},
     products: null,
     impact: 0,
     keys: []
    },
    prospectsHeaders: {
      assets: 0,
      effective_date: "",
      potential_premium: 0,
      prospects: 0,
    },
    productsData: {
        products: [],
        productsDiff: [],
        current: [],
        newBuisnes: [],
        retention: [],
        total: [],
        total_premium: 0,
        total_impact: 0,
        prospects: 0,
        premium: 0,
        premium_annual: 0,
        policies: 0,
        potential: 0,
        pote: 0,
        period: 0,
        unpdated_at: "",
        policiesLapse: 0,
        policiesTotal: 0,
        potentialLapse: 0,
        upside: 0,
        ui_runs: [],
        lapse_runs: [],
        cluster_runs: [],
    },
    opportunitiesData: {
        opportunitiesUI: [],
        opportunitiesLapse: [],
        total_policies: 0,
        total_premium: 0,
        policiesLapse: 0,
        premiumLapse: 0,
        policiesUI: 0,
        premiumUI: 0,
    },
    prospectsData: {
        prospects: [],
        keys: [],
        details: [],
        count: 0,
        total_premium: 0,
        uniqueStories: [],
        uniqueProducts: [],
        uniqueProductNames: [],
        uniqueFaceAmounts: [],
        durationSegments: [],
        unique_states: [],
        premiumLimits:[0,0],
        premiumLimitsMarks:[],
        premiumLimitsLapse:[0,0],
        premiumLimitsMarksLapse:[],
        face_amount_ranges: [],
        hidden_filters: [],
        sortKeys: {},
        thresholds_by_runs: {},
    },
    defaultProspectsColumns: localStorage.getItem("accountType") === "annuities" ? defaultColumnsAnnuities : defaultColumnsLife,
    filters: {
        product: null,
        premium: null,
        face_amount: null,
        gender: null,
        age: null,
        story: null,
        mean_premium_term: null,
        face_amount_prediction: null,
        storyType: null,
        state: null,
        res_state: null,
        priority: null,
        sensitivity: null,
        libr_status: null,
        Duration_segment: null,
        MARKET_NAME_LAG: null,
        lapse: {
            product: null,
            premium: null,
            face_amount_prediction: null,
            gender: null,
            age: null,
            story: null,
        },
        ui: {
            product: null,
            premium: null,
            face_amount: null,
            gender: null,
            age: null,
            story: null,
        }
    },
    queryFilters: {
      lapse: {},
      ui: {}
    },
    selectedPolicies: {},
    productsIsLoading: false,
    storiesIsLoading: false,
    activeTab: 'Impact on Premium'
    
}

const getMoneyQueryFromAction = (action) => {

  if (action.payload["All"]) { return null}

  const currency = '$'
  const clean = value => value.replace(currency, "").replace("<", "").replace("K", "000")
  const selectedEntries = Object.entries(action.payload).filter((item) => item[1])
  
  const query = []
  selectedEntries.forEach((filter) => {
    const value = filter[0]
    if (value.includes("-")) {
      const range = value.split("-")
      query.push({
        "operator": 'between',
        "value": `${nBuilder(clean(range[0]))},${nBuilder(clean(range[1]))}`
      })
    } else if (value[0] === "<") {
      query.push({"operator": 'lte', "value": `${nBuilder(clean(value))}`})
    } else if (value.includes("+")) {
      query.push({"operator": 'gte', "value": `${nBuilder(clean(value))}`})
    }
  })

  return query
}

const getPercentageQueryFromAction = (action) => {

    if (action.payload["All"]) { return null}
  
    const convert = value => parseFloat(value.replace('%', "").replace("<", ""))
    const selectedEntries = Object.entries(action.payload).filter((item) => item[1])
    
    const query = []
    selectedEntries.forEach((filter) => {
      const value = filter[0]
      if (value.includes("-")) {
        const range = value.split("-")
        query.push({
          "operator": 'between',
          "value": `${convert(range[0])},${convert(range[1])}`
        })
      } else if (value[0] === "<") {
        query.push({"operator": 'lte', "value": `${convert(value)}`})
      } else if (value.includes("+")) {
        query.push({"operator": 'gte', "value": `${convert(value)}`})
      }
    })
  
    return query
  }

const getQueryFromPlainAction = (action) => {
  const query = []

  const selected = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])

  if(selected.length > 0) {
      selected.forEach((key) => {

      if (!query.find(({value}) => value === key)) {
          query.push({
          "operator": "equals",
          "value": key
        })
      }
      
    })

    return query
  }

  return null
}


const getQueryFromPriority = (action, longType = false) => {
   
    const replacement = {
        "high": 'H', "medium": "M", "low": "L"
    }

  const query = []

  const selected = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
  if(selected.length > 0) {
    selected.forEach((key) => {

    if (!query.find(({value}) => value === key)) {
        query.push({
        "operator": "equals",
        "value": longType ? key[0]?.toUpperCase()+key.slice(1, key?.length) : replacement[key]
      })
    }
    
  })

  return query
}



return null
}

const getQueryFromSensitivity = (action) => {
   
  const replacement = {
      "high": 3, "medium": 2, "low": 1
  }

const query = []

const selected = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
if(selected.length > 0) {
  selected.forEach((key) => {
  if (!query.find(({value}) => value === key)) {
      query.push({
      "operator": "equals",
      "value": replacement[key]
    })
  }
  
})

return query
}



return null
}

const name = 'Book'

export const BookSlice = createSlice({
    name: name,
    initialState : initialState,
    reducers:{
        setProducts: (state, action) => {
            const data = action.payload.products
            let current = [];
            let newBuisnes = [];
            let retention = [];
            let total = [];
            data.forEach(({ product_type, total_premium, potential_premium_retained, upsell_premium_opportunity }) => {
                current.push({ id: product_type, value: total_premium })
                retention.push({ id: product_type, value: potential_premium_retained })
                if (upsell_premium_opportunity) {
                    const sum = total_premium + potential_premium_retained + upsell_premium_opportunity
                    newBuisnes.push({ id: product_type, value: upsell_premium_opportunity })
                    total.push({ id: product_type, value: sum })
                } else {
                    const sum = total_premium + potential_premium_retained
                    total.push({ id: product_type, value: sum })
                }
            })
            const retentionTotal = retention?.reduce((acc, cur) => {
                acc = acc + +cur.value;
                return acc;
            }, 0)
            const newBuisnesTotal = newBuisnes?.reduce((acc, cur) => {
                acc = acc + +cur.value;
                return acc;
            }, 0)
            const currentTotal = current?.reduce((acc, cur) => {
                acc = acc + +cur.value;
                return acc;
            }, 0)
            const totalValue = total?.reduce((acc, cur) => {
                acc = acc + +cur.value;
                return acc;
            }, 0)
            state.productsData.total_impact = action.payload?.total_premium
            state.productsData.products = data?.map((item) => ({ ...item, potential_premium: item.total_premium }));
            state.productsData.productsDiff = data?.map((item) => ({ ...item, potential_premium: item.total_premium + item.potential_premium, difference: item.potential_premium }));
            state.productsData.current = current;
            state.productsData.newBuisnes = newBuisnesTotal > 0 ? newBuisnes : [];
            state.productsData.retention = retentionTotal > 0 ? retention : [];
            state.productsData.total = total;
            state.productsData.premium = action.payload.total_premium;
            state.productsData.premium_opportunity = 
            action.payload.lapse_policies_potential_premium_retained
            + 
            action.payload.underinsured_policies_upsell_premium_opportunity
            state.productsData.premium_annual = totalValue
            state.productsData.upside = Math.floor((((totalValue.toFixed(3)/currentTotal.toFixed(3))-1)*100))
            state.productsData.prospects = action.payload.prospects_count;
            state.productsData.policies = action.payload.underinsured_policies_count;
            state.productsData.potential = action.payload.underinsured_policies_upsell_premium_opportunity;
            state.productsData.policiesLapse = action.payload.lapse_policies_count;
            const lapse = action.payload?.lapse_policies_count ? action.payload?.lapse_policies_count : 0
            const ui = action.payload?.underinsured_policies_count ? action.payload?.underinsured_policies_count : 0
            const uiPremium = action.payload?.underinsured_policies_upsell_premium_opportunity ? action.payload?.underinsured_policies_upsell_premium_opportunity : 0
            const lapsePremium = action.payload?.lapse_policies_potential_premium_retained ? action.payload?.lapse_policies_potential_premium_retained : 0
            state.productsData.policiesTotal = lapse + ui;
            state.productsData.potentialLapse = action.payload.lapse_policies_potential_premium_retained;
            state.productsData.total_count = action.payload.total_count;
            state.productsData.lapse_runs = action.payload.lapse_runs;
            state.productsData.ui_runs = action.payload.ui_runs;
            state.productsData.total_premium = lapsePremium + uiPremium;
            state.productsData.period = action.payload?.period_months ? action.payload.period_months : 3;
            state.productsData.unpdated_at = action.payload.last_updated;
        },
        setOpportunities: (state, action) => {
            let assigneblePoliciesLapse = 0;
            let assigneblePremiumLapse = 0;
            let assigneblePoliciesUI = 0;
            let assigneblePremiumUI = 0;
            //filter stories
            const opportunitiesLapse = action.payload?.lapse_summary?.stories
            ?.sort((a, b) => a.name?.toLowerCase() === "possible policy update needed" ? -1 : b.name?.toLowerCase() === "possible policy update needed" ? 1 : 0)
            ?.map((item, idx) => {
              assigneblePoliciesLapse = assigneblePoliciesLapse + item.assignable_prospects
              assigneblePremiumLapse = assigneblePremiumLapse + item.assignable_prospects_potential_premium
              return ({ ...item, opportunity: item.name, campaign: "Postpone"})
            })
            ?.filter(({ prospects }) => prospects > 0)
            const opportunitiesUI = action.payload?.ui_summary?.stories
            ?.sort((a, b) => a.name?.toLowerCase() === "possible policy update needed" ? -1 : b.name?.toLowerCase() === "possible policy update needed" ? 1 : 0)
            ?.map((item, idx) => {
              assigneblePoliciesUI = assigneblePoliciesUI + item.assignable_prospects
              assigneblePremiumUI = assigneblePremiumUI + item.assignable_prospects_potential_premium
              return ({ ...item, opportunity: item.name, campaign: "Postpone"})
            })
            ?.filter(({ prospects }) => prospects > 0)
            
            //insert no indicators story at the top of the list
            if (opportunitiesLapse) {
              const noIndicatorIndexLapse = opportunitiesLapse?.findIndex(({opportunity}) => opportunity?.toLowerCase() === "no indicators")
              state.opportunitiesData.opportunitiesLapse = noIndicatorIndexLapse !== -1 ? 
              [
                ...opportunitiesLapse.slice(0, noIndicatorIndexLapse), 
                ...opportunitiesLapse.slice(noIndicatorIndexLapse+1), 
                { ...opportunitiesLapse[noIndicatorIndexLapse], priority: "low"}
              ] 
              : opportunitiesLapse              
            } else {
              state.opportunitiesData.opportunitiesLapse = []
            }
        
            if (opportunitiesUI) {
              const noIndicatorIndexUI = opportunitiesUI?.findIndex(({opportunity}) => opportunity?.toLowerCase() === "no indicators")
              state.opportunitiesData.opportunitiesUI = noIndicatorIndexUI !== -1 ? 
              [
                ...opportunitiesUI.slice(0, noIndicatorIndexUI), 
                ...opportunitiesUI.slice(noIndicatorIndexUI+1), 
                { ...opportunitiesUI[noIndicatorIndexUI], priority: "low"}
              ] 
              : opportunitiesUI
            } else {
              state.opportunitiesData.opportunitiesUI = []
            }

            //set totals
            const policiesLapse = action.payload?.lapse_summary?.prospects_count ? action.payload.lapse_summary.prospects_count : 0;
            const premiumLapse = action.payload?.lapse_summary?.potential_premium ? action.payload.lapse_summary.potential_premium : 0;
            const policiesUI = action.payload?.ui_summary?.prospects_count ? action.payload.ui_summary.prospects_count : 0;
            const premiumUI = action.payload?.ui_summary?.potential_premium ? action.payload.ui_summary.potential_premium : 0;
            
            state.opportunitiesData.policiesLapse = policiesLapse
            state.opportunitiesData.premiumLapse = premiumLapse
            state.opportunitiesData.policiesUI = policiesUI
            state.opportunitiesData.premiumUI = premiumUI
            state.opportunitiesData.total_policies = policiesLapse + policiesUI
            state.opportunitiesData.total_premium = premiumLapse + premiumUI
            state.opportunitiesData.total_assignable_prospects = assigneblePoliciesLapse + assigneblePoliciesUI
            state.opportunitiesData.total_assignable_prospects_potential_premium = assigneblePremiumLapse + assigneblePremiumUI
            
            //set runs
            state.productsData.lapse_runs = 
            action.payload?.lapse_runs ?
            action.payload.lapse_runs : [] 
            state.productsData.ui_runs = 
            action.payload?.ui_runs ?
            action.payload.ui_runs : []
            state.productsData.cluster_runs = 
            action.payload?.cluster_runs_ids ?
            action.payload.cluster_runs_ids : []
        },
        setOpportunitiesBroker: (state, action) => {
          state.opportunitiesData.opportunitiesLapse = action.payload?.stories ? action.payload.stories : []
        },
        setProspectsData: (state, action) => {
          const currency = getCountryCurrencySymbol()
          const isNewLapseColumns = action.payload.results?.length > 0 ? action.payload.results[0]["annualized_premium_sensitivity_NP"] !== undefined : false
          if (isNewLapseColumns) {
            state.defaultProspectsColumns = Object.assign({}, ...Object.keys(defaultColumnsNewLapse)
              .filter((key) => filterMissingFields(key, action.payload.results, sortKeysRunNewLapse))
              .map((key) => ({ [key]: defaultColumnsNewLapse[key]})))
            state.prospectsData.sortKeys = sortKeysRunNewLapse
          } else {
            state.prospectsData.sortKeys = sortKeysRunLife
            state.defaultProspectsColumns = Object.assign({}, ...Object.keys(defaultColumnsLife)
              .filter((key) => filterMissingFields(key, action.payload.results, sortKeysRunLife))
              .map((key) => ({ [key]: defaultColumnsLife[key]})))
          }
            state.prospectsData.prospects = 
            action.payload.results?.length !== undefined ?
            action.payload.results.map(({
                policy,
                product,
                issue_date,
                face_amount,
                age,
                story,
                account_value,
                mean_premium_term,
                premium,
                annualized_premium,
                annualized_premium_res,
                annualized_premium_sensitivity_NP,
                MARKET_NAME_LAG,
                lapse_rank,
                model_type,
                per_cap_income,
                high_m_res,
                med_m_res,
                low_m_res,
                City,
                State,
                run_id
            }) => ({
              policy,
              product_name: MARKET_NAME_LAG,
              product_type: product,
              age: Math.floor(age),
              premium,
              face_amount,
              account_value,
              issue_date,
              story,
              annualized_premium_res,
              annualized_premium_sensitivity_NP,
              mean_premium_term: model_type === "lapse" ? annualized_premium : mean_premium_term,
              label: getLapseRisk(
                { high_m_res, med_m_res, low_m_res }, 
                action.payload?.thresholds_by_runs ? Object.keys(action.payload.thresholds_by_runs)?.map((key) => action.payload.thresholds_by_runs[key])[0] : null
              ),
              lapse_rank,
              per_cap_income,
              model_type,
              City,
              State,
              run_id
            })
            ) : []
            state.prospectsData.thresholds_by_runs = action.payload.thresholds_by_runs
            state.prospectsData.count = action.payload.count
            state.prospectsData.details = action.payload.results
            ?.map((item) => {
              const { high_m_res, med_m_res, low_m_res } = item
              return { 
                ...item,
                label: getLapseRisk(
                  { high_m_res, med_m_res, low_m_res }, 
                  action.payload?.thresholds_by_runs ? Object.keys(action.payload.thresholds_by_runs)?.map((key) => action.payload.thresholds_by_runs[key])[0] : null
                )
              }
            })
            state.prospectsData.keys = action.payload.results.length > 0 ? Object.keys(action.payload.results[0]) : []
            state.prospectsData.total_premium = action.payload.potential_premium
            if (state.prospectsData.uniqueStories.length === 0) {
                const behavioralCluster = {
                  "All": true,
                }
                action.payload?.unique_stories.forEach((item) => Object.assign(behavioralCluster, {[item]: false}))
                state.prospectsData.uniqueStories = behavioralCluster                  
            }
            if (state.prospectsData.uniqueProducts.length === 0) {
                const products = {
                  "All": true,
                }
                action.payload?.unique_product_types.forEach((item) => Object.assign(products, {[item]: false}))
                state.prospectsData.uniqueProducts = products                  
            }
            if (state.prospectsData.uniqueProductNames.length === 0) {
                const names = {
                  "All": true,
                }
                action.payload?.unique_product_names.forEach((item) => Object.assign(names, {[item]: false}))
                state.prospectsData.uniqueProductNames = names                  
            }
            if (state.prospectsData.durationSegments.length === 0) {
                const segments = {
                  "All": true,
                }
                action.payload?.duration_segments.forEach((item) => Object.assign(segments, {[item]: false}))
                state.prospectsData.durationSegments = segments                  
            }
            if (state.prospectsData.uniqueFaceAmounts.length === 0) {
                const face_amount_predictions = {
                  "All": true,
                }
                action.payload?.unique_face_amount_prediction.forEach((item) => Object.assign(face_amount_predictions, {[item?.toUpperCase()]: false}))
                state.prospectsData.uniqueFaceAmounts = face_amount_predictions       
            }
            if (state.prospectsData.face_amount_ranges.length === 0 && action.payload.face_amount_median?.length) {
                const faceAmountRanges = {
                  "All": true,
                }
                Object.assign(faceAmountRanges, {["<" + nFormatter(action.payload.face_amount_median[0], 2, currency)]: false})
                action.payload.face_amount_median.forEach(
                    (range, index) => {
                      if(index !== 0 && index !== action.payload.face_amount_median.length-1) {
                        Object.assign(faceAmountRanges, {[
                          nFormatter(action.payload.face_amount_median[index-1], 2, currency)
                          + "-" + nFormatter(range, 2, currency)
                              ]: false})
                      }
                   }
                )
                Object.assign(faceAmountRanges, {[nFormatter(action.payload.face_amount_median[action.payload.face_amount_median.length-1], 2, currency)+"<"]: false})
                state.prospectsData.face_amount_ranges = faceAmountRanges                  
            }
            if (!action.payload.face_amount_median?.length) {
              state.prospectsData.hidden_filters = [ ...state.prospectsData.hidden_filters, "Face Amount"]
            }
            if (state.prospectsData.unique_states.length === 0) {
              const states = {
                "All": true,
              }
              const values = action.payload?.unique_states
                ?.map((label) => usStatesData.find(({ abbreviation }) => abbreviation === label)?.name)
              values?.forEach((item) => Object.assign(states, {[item?.toUpperCase()]: false}))
              
              state.prospectsData.unique_states = states    
          }
            if (state.filters.premium === null && state.filters.mean_premium_term === null) {
              state.prospectsData.premiumLimits = [0, action.payload.max_premium]
              state.prospectsData.premiumLimitsMarks = { value: action.payload.max_premium, label: nFormatter(action.payload.max_premium, 1, currency)}
              state.prospectsData.premiumLimitsLapse = [0, action.payload.max_potential_premium]
              state.prospectsData.premiumLimitsMarksLapse = { value: action.payload.max_potential_premium, label: nFormatter(action.payload.max_potential_premium, 1, currency)}
            }
        },
        setProspectsDataAE: (state, action) => {          
          const currency = getCountryCurrencySymbol()
          state.prospectsData.sortKeys = sortKeysAnnuities
          state.defaultProspectsColumns = Object.assign({}, ...Object.keys(defaultColumnsAnnuities)
            .filter((key) => filterMissingFields(key, action.payload.results, sortKeysAnnuities))
            .map((key) => ({ [key]: defaultColumnsAnnuities[key]})))
          state.prospectsData.prospects = 
          action.payload.results?.length !== undefined ?
          action.payload.results.map(({
            policy,
            product_name,
            product,
            state,
            age,
            gender,
            zipcode,
            zip_code,
            cash_value,
            CSV_LAG,
            TARGETED_AUM,
            RETAINED_POLICIES,
            RETAINED_AUM,
            ROLLOVER_AUM,
            YRS_REMAIN_SC,
            SURRCHRG_END_DATE_LAG,
            IAV_LAG,
            RIDER_STATUS_LAG_WITH_FEE,
            CURR_LIBR_WITH_AMOUNT_LAG,
            QTD_LIBR_WITHS_MONTH_CUMSUM_LAG,
            RATIO_WITHS_LAG,
            SUB_ACTIVITY,
            CONTRACT_LOSS,
            CONTRACT_LOSS_DOLLARS,
            INCOME_ACOUNT_LOSS,
            INCOME_ACOUNT_LOSS_DOLLARS,
            QTD_WITHS_MONTH_CUMSUM_LAG,
            RES_STATE_LAG,
            servicing_agent_deid,
            AGENT_STATUS,
            TRUST_FLAG,
            ATTORNEY_POWER_FLAG,
            CORPORAGE_OWNED,
            surrender_score_platform,
            story_lapse,
            model_type,
            potential_premium_retained,
            mean_premium_term,
            lapse_rank_bin,
            duration_segment,
            premium_paid_amount,
            City,
            run_id
          }, index) => ({
            policy,
            product_type: product,
            product_market_name: product_name,
            age,
            contract_value: cash_value,
            years: YRS_REMAIN_SC,
            account_value: IAV_LAG,
            surr_value: CSV_LAG,
            libr_status: RIDER_STATUS_LAG_WITH_FEE,
            libr_payment: QTD_LIBR_WITHS_MONTH_CUMSUM_LAG,
            withdraw_activity: priorityMaping[RATIO_WITHS_LAG] ? priorityMaping[RATIO_WITHS_LAG] : "N/A",
            sub_activity: priorityMaping[SUB_ACTIVITY] ? priorityMaping[SUB_ACTIVITY] : "N/A",
            contract_loss: CONTRACT_LOSS,
            contract_loss_dollars: CONTRACT_LOSS_DOLLARS,
            income_loss: INCOME_ACOUNT_LOSS,
            income_loss_dollars: INCOME_ACOUNT_LOSS_DOLLARS,
            withdraw_annual: QTD_WITHS_MONTH_CUMSUM_LAG,
            issue_state: state,
            residence_state: RES_STATE_LAG,
            agent_id: servicing_agent_deid,
            agent_status: AGENT_STATUS,
            duration_segment,
            premium_paid_amount,
            story: story_lapse,
            aum_upside: model_type?.toLowerCase()?.includes("lapse") ? potential_premium_retained : mean_premium_term,
            priority: lapse_rank_bin,
            //popup only fields
            model_type,
            gender,
            zipcode: zipcode || zip_code,
            targeted_aum: TARGETED_AUM,
            retained_policies: RETAINED_POLICIES,
            retained_aum: RETAINED_AUM,
            rollover_aum: ROLLOVER_AUM,
            end_of_surr: SURRCHRG_END_DATE_LAG,
            libr_fee: CURR_LIBR_WITH_AMOUNT_LAG,
            owned_by_trust: TRUST_FLAG,
            power_of_attroney: ATTORNEY_POWER_FLAG,
            corparate_owned: CORPORAGE_OWNED,
            surr_prob: surrender_score_platform,
            City,
            State: state,
            run_id
          })) : []
          state.prospectsData.thresholds_by_runs = action.payload.thresholds_by_runs
          state.prospectsData.count = action.payload.count
          state.prospectsData.details =  action.payload.results
          state.prospectsData.keys = action.payload.results.length > 0 ? Object.keys(action.payload.results[0]) : []
          state.prospectsData.total_premium = action.payload.potential_premium
          if (state.prospectsData.uniqueStories.length === 0) {
              const behavioralCluster = {
                "All": true,
              }
              action.payload?.unique_stories.forEach((item) => Object.assign(behavioralCluster, {[item]: false}))
              state.prospectsData.uniqueStories = behavioralCluster                  
          }
          if (state.prospectsData.uniqueProducts.length === 0) {
              const products = {
                "All": true,
              }
              action.payload?.unique_product_types.forEach((item) => Object.assign(products, {[item]: false}))
              state.prospectsData.uniqueProducts = products                  
          }
          if (state.prospectsData.uniqueProductNames.length === 0) {
              const names = {
                "All": true,
              }
              action.payload?.unique_product_names.forEach((item) => Object.assign(names, {[item]: false}))
              state.prospectsData.uniqueProductNames = names                  
          }
          if (state.prospectsData.durationSegments.length === 0) {
              const segments = {
                "All": true,
              }
              action.payload?.duration_segments.forEach((item) => Object.assign(segments, {[item]: false}))
              state.prospectsData.durationSegments = segments                  
          }
          if (state.prospectsData.uniqueFaceAmounts.length === 0) {
              const face_amount_predictions = {
                "All": true,
              }
              action.payload?.unique_face_amount_prediction.forEach((item) => Object.assign(face_amount_predictions, {[item?.toUpperCase()]: false}))
              state.prospectsData.uniqueFaceAmounts = face_amount_predictions       
          }
          if (state.prospectsData.unique_states.length === 0) {
              const states = {
                "All": true,
              }
              const values = action.payload?.unique_states
                ?.map((label) => usStatesData.find(({ abbreviation }) => abbreviation === label)?.name)
              values?.forEach((item) => Object.assign(states, {[item?.toUpperCase()]: false}))
              
              state.prospectsData.unique_states = states    
          }
          if (state.prospectsData.face_amount_ranges.length === 0) {
              const faceAmountRanges = {
                "All": true,
              }
              Object.assign(faceAmountRanges, {["<" + nFormatter(action.payload.face_amount_median[0], 2, currency)]: false})
              action.payload.face_amount_median.forEach(
                  (range, index) => {
                    if(index !== 0 && index !== action.payload.face_amount_median.length-1) {
                      Object.assign(faceAmountRanges, {[
                        nFormatter(action.payload.face_amount_median[index-1], 2, currency)
                        + "-" + nFormatter(range, 2, currency)
                            ]: false})
                    }
                 }
              )
              Object.assign(faceAmountRanges, {[nFormatter(action.payload.face_amount_median[action.payload.face_amount_median.length-1], 2, currency)+"<"]: false})
              state.prospectsData.face_amount_ranges = faceAmountRanges                  
          }
          if (state.filters.premium === null && state.filters.mean_premium_term === null) {
            state.prospectsData.premiumLimits = [0, action.payload.max_premium]
            state.prospectsData.premiumLimitsMarks = { value: action.payload.max_premium, label: nFormatter(action.payload.max_premium, 1, currency)}
            state.prospectsData.premiumLimitsLapse = [0, action.payload.max_potential_premium]
            state.prospectsData.premiumLimitsMarksLapse = { value: action.payload.max_potential_premium, label: nFormatter(action.payload.max_potential_premium, 1, currency)}
          }
        },
        setProspectsDataBroker: (state, action) => {
            const currency = getCountryCurrencySymbol()
            state.prospectsData.sortKeys = sortKeysBroker
            state.defaultProspectsColumns = Object.assign({}, ...Object.keys(defaultColumnsBroker)
              .map((key) => ({ [key]: defaultColumnsBroker[key]})))
            state.prospectsData.prospects = 
            action.payload.policies.map(({
                id,
                under_insured,
                policy,
                first_name,
                last_name,
                product,
                product_name,
                age_at_issue,
                annualized_premium,
                annualized_premium_recommended,
                face_amount,
                cash_value,
                issue_date,
                dob,
                story_lapse,
                story_upsell,
                rank,
                annualized_premium_sensitivity_DML,
            }) => ({
                id,
                policy,
                client_name: getNameString(first_name, last_name),
                product,
                product_name,
                age: age_at_issue,
                premium: annualized_premium,
                face_amount,
                cash_value,
                issue_date,
                dob: getDateString(dob),
                story: under_insured === 1 ? story_upsell : story_lapse,
                mean_premium_term: annualized_premium_recommended,
                priority: annualized_premium_sensitivity_DML,
                upsell_rank: rank,
            })
            )
            state.prospectsData.thresholds_by_runs = action.payload.precision_config
            state.prospectsData.count = action.payload.count
            state.prospectsData.details = action.payload.policies.map((column) => ({
              ...column,
              address: column.address ? column.address : column.state,
              current: column.annualized_premium_recommended,
              recommend: column.annualized_premium_recommended !== column.annualized_premium ? column.annualized_premium_recommended-column.annualized_premium : column.annualized_premium,
              story: column.under_insured === 1 ? column.story_upsell : column.story_lapse,
              client_name: getNameString(column.first_name, column.last_name),
              dob: moment(getDateString(column.dob)).format("MM/DD/YYYY"),
            }))
            state.prospectsData.total_premium = action.payload.metadata.total_premium
            
            if (state.prospectsData.uniqueStories.length === 0) {
                const behavioralCluster = {
                  "All": true,
                }
                action.payload.metadata?.unique_lapse_stories.forEach((item) => Object.assign(behavioralCluster, {[item]: false}))
                state.prospectsData.uniqueStories = behavioralCluster                  
            }
            if (state.prospectsData.uniqueProducts.length === 0) {
                const products = {
                  "All": true,
                }
                action.payload.metadata?.unique_product_types.forEach((item) => Object.assign(products, {[item]: false}))
                state.prospectsData.uniqueProducts = products                  
            }
            if (state.prospectsData.durationSegments.length === 0) {
                const segments = {
                  "All": true,
                }
                action.payload.metadata?.duration_segments.forEach((item) => Object.assign(segments, {[item]: false}))
                state.prospectsData.durationSegments = segments                  
            }
            if (state.prospectsData.face_amount_ranges.length === 0) {
                const faceAmountRanges = {
                  "All": true,
                }
                Object.assign(faceAmountRanges, {["<" + nFormatter(action.payload.metadata.face_amount_median[0], 2, currency)]: false})
                action.payload.metadata.face_amount_median.forEach(
                    (range, index) => {
                      if(index !== 0 && index !== action.payload.metadata.face_amount_median.length-1) {
                        Object.assign(faceAmountRanges, {[
                          nFormatter(action.payload.metadata.face_amount_median[index-1], 2, currency)
                          + "-" + nFormatter(range, 2, currency)
                              ]: false})
                      }
                   }
                )
                Object.assign(faceAmountRanges, {[nFormatter(action.payload.metadata.face_amount_median[action.payload.metadata.face_amount_median.length-1], 2, currency)+"<"]: false})
                state.prospectsData.face_amount_ranges = faceAmountRanges                  
            }
            
          if (state.prospectsData.unique_states.length === 0) {
            const states = {
              "All": true,
            }
            const values = action.payload.metadata?.unique_states
              ?.map((label) => usStatesData.find(({ abbreviation }) => abbreviation === label)?.name)
            values?.forEach((item) => Object.assign(states, {[item?.toUpperCase()]: false}))
            state.prospectsData.unique_states = states    
          }
        },
        setProspectsList: (state, action) => {
            state.prospectsData.prospects = 
            action.payload?.length !== undefined ?
            action.payload.map(({
                policy,
                MARKET_NAME_LAG,
                product,
                premium,
                face_amount,
                age,
                cash_value,
                issue_date,
                story,
                mean_premium_term,
                model_type
            }, index) => ({
                policy,
                product_type: product,
                product_name: MARKET_NAME_LAG,
                age,
                premium,
                face_amount,
                cash_value,
                issue_date,
                story,
                mean_premium_term: model_type === "lapse" ? premium : mean_premium_term,
                priority: priority[index%3],
                model_type
            }))
            : []
        },
        setProspectsListAE: (state, action) => {
            state.prospectsData.prospects = 
            action.payload?.length !== undefined ?
            action.payload.map(({
              policy,
              MARKET_NAME_LAG,
              product,
              State,
              issue_date,
              age,
              gender,
              zipcode,
              zip_code,
              cash_value,
              CSV_LAG,
              TARGETED_AUM,
              RETAINED_POLICIES,
              RETAINED_AUM,
              ROLLOVER_AUM,
              YRS_REMAIN_SC,
              SURRCHRG_END_DATE_LAG,
              IAV_LAG,
              RIDER_STATUS_LAG_WITH_FEE,
              CURR_LIBR_WITH_AMOUNT_lag,
              QTD_LIBR_WITHS_MONTH_CUMSUM_LAG,
              RATIO_WITHS_LAG,
              SUB_ACTIVITY,
              CONTRACT_LOSS,
              CONTRACT_LOSS_DOLLARS,
              INCOME_ACOUNT_LOSS,
              INCOME_ACOUNT_LOSS_DOLLARS,
              QTD_WITHS_MONTH_CUMSUM_LAG,
              RES_STATE_LAG,
              AGENT_ID_LAG,
              AGENT_STATUS,
              JOINT_OWNER,
              TRUST_FLAG,
              ATTORNEY_POWER_FLAG,
              CORPORAGE_OWNED,
              surrender_score_platform,
              story,
              model_type,
              potential_premium_retained,
              mean_premium_term,
              PRIORITY
            }) => ({
              policy,
              product_type: product,
              product_market_name: MARKET_NAME_LAG,
              age,
              contract_value: cash_value,
              years: YRS_REMAIN_SC,
              account_value: IAV_LAG,
              surr_value: CSV_LAG,
              libr_status: RIDER_STATUS_LAG_WITH_FEE,
              libr_payment: QTD_LIBR_WITHS_MONTH_CUMSUM_LAG,
              withdraw_activity: priority[RATIO_WITHS_LAG] ? priority[RATIO_WITHS_LAG] : "N/A",
              sub_activity: priority[SUB_ACTIVITY] ? priority[SUB_ACTIVITY] : "N/A",
              contract_loss: CONTRACT_LOSS,
              contract_loss_dollars: CONTRACT_LOSS_DOLLARS,
              income_loss: INCOME_ACOUNT_LOSS,
              income_loss_dollars: INCOME_ACOUNT_LOSS_DOLLARS,
              withdraw_annual: QTD_WITHS_MONTH_CUMSUM_LAG,
              issue_state: State,
              residence_state: RES_STATE_LAG,
              agent_id: AGENT_ID_LAG,
              agent_status: AGENT_STATUS,
              joint_owner: JOINT_OWNER,
              story,
              priority: PRIORITY,
              //popup only fields
              model_type,
              gender,
              zipcode: zipcode || zip_code,
              targeted_aum: TARGETED_AUM,
              retained_policies: RETAINED_POLICIES,
              retained_aum: RETAINED_AUM,
              rollover_aum: ROLLOVER_AUM,
              end_of_surr: SURRCHRG_END_DATE_LAG,
              libr_fee: CURR_LIBR_WITH_AMOUNT_lag,
              owned_by_trust: TRUST_FLAG,
              power_of_attroney: ATTORNEY_POWER_FLAG,
              corparate_owned: CORPORAGE_OWNED,
              surr_prob: surrender_score_platform,
              aum_upside: model_type?.toLowerCase()?.includes("lapse") ? potential_premium_retained : mean_premium_term,
            }))
            : []
        },
        setProductFilter: (state, action) => {
                const queryByProduct = []
                const allSelectedProducts = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
                if(allSelectedProducts.length > 0) {
                    allSelectedProducts.forEach((key) => {
                    if (!queryByProduct.find(({value}) => value === key)) {
                        queryByProduct.push({
                        "operator": "equals",
                        "value": key
                      })
                    }
                    state.filters.product = queryByProduct
                  })
                } else {
                  state.filters.product = null
                }
        },
        setProductNameFilter: (state, action) => {
          state.filters.MARKET_NAME_LAG = getQueryFromPlainAction(action)
        },
        setStoryFilter: (state, action) => {
                const queryByStory = []
                const allSelectedStories = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
                if(allSelectedStories.length > 0) {
                    allSelectedStories.forEach((key) => {
                    if (!queryByStory.find(({value}) => value === key)) {
                        queryByStory.push({
                        "operator": "equals",
                        "value": key
                      })
                    }
                    state.filters.story = queryByStory
                  })
                } else {
                  state.filters.story = null
                }
        },
        setSurrYearValueFilter: (state, action) => {
          const query = []
            const allSelected = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
            if(allSelected.length > 0) {
                allSelected.forEach((key) => {
                if (!query.find(({value}) => value === key)) {
                    query.push({
                    "operator": "equals",
                    "value": key
                  })
                }
                state.filters.surrender_year_value = query
              })
            } else {
              state.filters.surrender_year_value = null
            }
        },
        setAgentStatusFilter: (state, action) => {
          state.filters.agent_status = getQueryFromPlainAction(action)
        },
        setDurationFilter: (state, action) => {
          state.filters.Duration_segment = getQueryFromPlainAction(action)
        },
        setContractLossFilter: (state, action) => {
          state.filters.contract_loss = getPercentageQueryFromAction(action)   
        },        
        setIncomeLossFilter: (state, action) => {
          state.filters.income_lost = getPercentageQueryFromAction(action)  
        },
        setStoryTypeFilter: (state, action) => {
                if(action.payload["Retention"]) {
                  state.filters.storyType = "lapse"
                } else if(action.payload["New Business"]) {
                  state.filters.storyType = "ui"
                } else {
                  state.filters.storyType = null
                }
        },
        setAgeFilter: (state, action) => {
            if (!action.payload["All"]) {
                const queryByAge = []
                const ageFilterSelectedEntries = Object.entries(action.payload).filter((item) => item[1])
                ageFilterSelectedEntries.forEach((filter) => {
                    const value = filter[0]
                    if (value.includes("<") && value.replace("<", "") === "18") {
                    queryByAge.push({"operator": 'lte', "value": "18"})
                    } else if (value.includes("+") && value.replace("+", "") === "80") {
                    queryByAge.push({"operator": 'gte', "value": "80"})
                    } else {
                    const range = value.split("-")
                    if (value !== '1') {
                        queryByAge.push({
                        "operator": 'between',
                        "value": range.join(",")
                        })            
                    }
                    }
                })
                state.filters.age = queryByAge             
            } else {
                state.filters.age = null
            }
        },
        setGenderFilter: (state, action) => {
            if (!action.payload["All"]) {
                const queryByGender = []
                if(action.payload["Male"]) {
                  queryByGender.push({
                    "operator": "in",
                    "value":"M"
                  })
                }
                if(action.payload["Female"]) {
                  queryByGender.push({
                    "operator": "in",
                    "value":"F"
                  })
                }
                if(action.payload["Other"]) {
                  queryByGender.push({
                    "operator": "in",
                    "value":"O"
                  })
                }     
                state.filters.gender = queryByGender       
            } else {
                state.filters.gender = null
            }
        },
        setUIFaceAmountFilter: (state, action) => {
          const currency = getCountryCurrencySymbol()
            if (!action.payload["All"] && action.payload["All"] !== undefined) {
                const queryByFaceAmount = []
                const faceAmountFilterSelectedEntries = Object.entries(action.payload).filter((item) => item[1])
                faceAmountFilterSelectedEntries.forEach((filter) => {
                  const value = filter[0]
                  if (value[0] === "<") {
                    queryByFaceAmount.push({"operator": 'lte', "value": `${nBuilder(value.replace(`<${currency}`, ""))}`})
                  } else if (value.includes("+") || value[value.length-1] === "<") {
                    queryByFaceAmount.push({"operator": 'gte', "value": `${nBuilder(value.replace(currency, "").replace("+", ""))}`})
                  } else {
                    const range = value.split("-")
                    queryByFaceAmount.push({
                      "operator": 'between',
                      "value": `${nBuilder(range[0].replace(currency, ""))},${nBuilder(range[1].replace(currency, ""))}`
                    })
                  }
                })    
                state.filters.face_amount = queryByFaceAmount       
            } else {
                state.filters.face_amount = null
            }
        },
        setLapseFaceAmountFilter: (state, action) => {
            const queryByFaceAmount = []
            const allSelectedFaceAmounts = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
            if(allSelectedFaceAmounts.length > 0) {
                allSelectedFaceAmounts.forEach((key) => {
                if (!queryByFaceAmount.find(({value}) => value === key)) {
                    queryByFaceAmount.push({
                    "operator": "equals",
                    "value": key
                  })
                }
                state.filters.face_amount_prediction = queryByFaceAmount
              })
            } else {
              state.filters.face_amount_prediction = null
            }
        },
        setUIPremiumFilter: (state, action) => {
            const currency = getCountryCurrencySymbol()
            if (!action.payload["All"] && action.payload["All"] !== undefined) {
                const queryByIncome = []
                const incomeFilterSelectedEntries = Object.entries(action.payload).filter((item) => item[1])
                incomeFilterSelectedEntries.forEach((filter) => {
                  const value = filter[0]
                  if (value.includes("-")) {
                    const range = value.split("-")
                    queryByIncome.push({
                      "operator": 'between',
                      "value": `${nBuilder(range[0].replace(currency, "").replace("<", ""))},${nBuilder(range[1].replace(currency, "").replace("<", ""))}`
                    })
                  } else if (value[0] === "<") {
                    queryByIncome.push({"operator": 'lte', "value": `${nBuilder(value.replace(currency, "").replace("<", ""))}`})
                  } else if (value.includes("<")) {
                    queryByIncome.push({"operator": 'gte', "value": `${nBuilder(value.replace(currency, "").replace("<", ""))}`})
                  }
                })    
                state.filters.premium = queryByIncome       
            } else {
                state.filters.premium = null
            }
        },
        setCashValueFilter: (state, action) => {
            const currency = getCountryCurrencySymbol()
            if (!action.payload["All"] && action.payload["All"] !== undefined) {
                const queryByIncome = []
                const incomeFilterSelectedEntries = Object.entries(action.payload).filter((item) => item[1])
                incomeFilterSelectedEntries.forEach((filter) => {
                  const value = filter[0]
                  if (value.includes("-")) {
                    const range = value.split("-")
                    queryByIncome.push({
                      "operator": 'between',
                      "value": `${nBuilder(range[0].replace(currency, "").replace("<", ""))},${nBuilder(range[1].replace(currency, "").replace("<", ""))}`
                    })
                  } else if (value[0] === "<") {
                    queryByIncome.push({"operator": 'lte', "value": `${nBuilder(value.replace(currency, "").replace("<", ""))}`})
                  } else if (value.includes("<")) {
                    queryByIncome.push({"operator": 'gte', "value": `${nBuilder(value.replace(currency, "").replace("<", ""))}`})
                  }
                })    
                state.filters.cash_value = queryByIncome       
            } else {
                state.filters.cash_value = null
            }
        },
        setLapsePremiumFilter: (state, action) => {
            const currency = getCountryCurrencySymbol()
            if (!action.payload["All"] && action.payload["All"] !== undefined) {
                const queryByIncome = []
                const incomeFilterSelectedEntries = Object.entries(action.payload).filter((item) => item[1])
                incomeFilterSelectedEntries.forEach((filter) => {
                  const value = filter[0]
                  if (value.includes("-")) {
                    const range = value.split("-")
                    queryByIncome.push({
                      "operator": 'between',
                      "value": `${nBuilder(range[0].replace(currency, ""))},${nBuilder(range[1].replace(currency, ""))}`
                    })
                  } else if (value.includes("+")) {
                    queryByIncome.push({"operator": 'gte', "value": `${nBuilder(value.replace(currency, "").replace("+", ""))}`})
                  } else if (value[0] === "<") {
                    queryByIncome.push({"operator": 'lte', "value": `${nBuilder(value.replace(`<${currency}`, ""))}`})
                  }
                })    
                state.filters.potential_premium_retained = queryByIncome       
            } else {
                state.filters.potential_premium_retained = null
            }
        },
        setPremiumTermFilter: (state, action) => {
            const currency = getCountryCurrencySymbol()
            if (!action.payload["All"] && action.payload["All"] !== undefined) {
                const queryByIncome = []
                const incomeFilterSelectedEntries = Object.entries(action.payload).filter((item) => item[1])
                incomeFilterSelectedEntries.forEach((filter) => {
                  const value = filter[0]
                  if (value.includes("-")) {
                    const range = value.split("-")
                    queryByIncome.push({
                      "operator": 'between',
                      "value": `${nBuilder(range[0].replace(currency, ""))},${nBuilder(range[1].replace(currency, ""))}`
                    })
                  } else if (value.includes("+")) {
                    queryByIncome.push({"operator": 'gte', "value": `${nBuilder(value.replace(currency, "").replace("+", ""))}`})
                  } else if (value[0] === "<") {
                    queryByIncome.push({"operator": 'lte', "value": `${nBuilder(value.replace(`<${currency}`, ""))}`})
                  }
                })    
                state.filters.mean_premium_term = queryByIncome       
            } else {
                state.filters.mean_premium_term = null
            }
        },
        setContractValueFilter: (state, action) => {
            state.filters.cash_value = getMoneyQueryFromAction(action)       
        },
        setSurrValueFilter: (state, action) => {
            state.filters.surrender_value = getMoneyQueryFromAction(action)      
        },        
        setLibrFilter: (state, action) => {
              state.filters.libr_payment = getMoneyQueryFromAction(action)       
        },
        setIncomeAccFilter: (state, action) => {
            state.filters.income_value = getMoneyQueryFromAction(action)       
        },
        setWithdravalActivityFilter: (state, action) => {
          state.filters.withdraw_activity = getQueryFromPriority(action)
        },
        setSubActivityFilter: (state, action) => {
          state.filters.sub_activity = getQueryFromPriority(action)
        },
        setPriorityFilter: (state, action) => {
          state.filters.priority = getQueryFromPriority(action, true)
        },
        setSensitivityFilter: (state, action) => {
          state.filters.sensitivity = getQueryFromSensitivity(action, true)
        },
        setLIBRStatusFilter: (state, action) => {
          state.filters.libr_status = getQueryFromPlainAction(action)
        },
        setStateFilter: (state, action) => {
                const queryByState = []
                const allSelectedState = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
                if(allSelectedState.length > 0) {
                  allSelectedState.forEach((key) => {
                    const stateKey = usStatesData.find(({ name }) => name.toUpperCase() === key)?.abbreviation
                    if (!queryByState.find(({value}) => value === stateKey)) {
                      queryByState.push({
                        "operator": "equals",
                        "value": stateKey
                      })
                    }
                    state.filters.state = queryByState
                  })
                } else {
                  state.filters.state = null
                }
        },
        setResStateFilter: (state, action) => {
                const queryByResState = []
                const allSelectedResState = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
                if(allSelectedResState.length > 0) {
                  allSelectedResState.forEach((key) => {
                    const stateKey = usStatesData.find(({ name }) => name.toUpperCase() === key)?.abbreviation
                    if (!queryByResState.find(({value}) => value === stateKey)) {
                      queryByResState.push({
                        "operator": "equals",
                        "value": stateKey
                      })
                    }
                    state.filters.res_state = queryByResState
                  })
                } else {
                  state.filters.res_state = null
                }
        },
        setQueryFilters: (state, action) => {
          state.queryFilters = action.payload
        },
        setActiveTab: (state, action) => {
          state.activeTab = action.payload
        },
        setGroupByProject: (state, action) => {
          state.groupByProject = action.payload
        },
        setProjectIds: (state, action) => {
          state.projectIds = action.payload
        },
        setProjectLabels: (state, action) => {
          state.projectLabels = action.payload
        },
        setRunsMeta: (state, action) => {
          const runs = []
          action.payload.projects?.forEach((project) => {
            project.runs?.forEach(({ run_id, run_name, model}) => {
              runs.push({ run_id, model, name: `${project.project_name} - ${run_name}`})
            })
          })
          state.runsMeta = runs
        },
        setProspectsRuns: (state, action) => {
          state.prospectsHeaders = {
            assets: action.payload.assets,
            effective_date: action.payload?.effective_date ? moment(action.payload?.effective_date).format("MMM DD, YYYY") : "",
            potential_premium: action.payload.potential_premium,
            prospects: action.payload.prospects,
          }
          state.productsData.lapse_runs = action.payload.lapse_runs_ids
            .map((item) => ({ run_id: item }))
          state.productsData.ui_runs = action.payload.ui_runs_ids
            .map((item) => ({ run_id: item }))
            state.productsData.cluster_runs = action.payload.cluster_runs_ids
              .map((item) => ({ run_id: item }))
        },
        setProspectsHeaders: (state, action) => {
          state.prospectsHeaders = {
            assets: action.payload.assets,
            effective_date: action.payload?.effective_date ? moment(action.payload?.effective_date).format("MMM DD, YYYY") : "",
            potential_premium: action.payload.potential_premium,
            prospects: action.payload.prospects,
          }
        },
        setEffectiveDate: (state, action) => {
          state.prospectsHeaders = {
            assets: action.payload.assets,
            effective_date: action.payload?.effective_date ? moment(action.payload?.effective_date).format("MMM DD, YYYY") : "",
            potential_premium: action.payload.potential_premium,
            prospects: action.payload.prospects,
          }
        },
        setImpactsData: (state, action) => {
          let items = []
          let original = 0
          let potential = 0
          const products = []
          action.payload?.performance && Object.keys(action.payload.performance)
            .forEach((key) => {
              items = [
                ...items,
                ...Object.keys(action.payload.performance[key])
                  .filter((item) => !items.includes(item))
              ]
            })
          items
            .forEach((key, index) => {
              original = original + action.payload?.performance?.original[key]
              potential = potential + action.payload?.performance?.potential[key]
              products.push({
              label: key,
              original: action.payload?.performance?.original[key],
              lapse: action.payload?.performance?.lapse[key],
              ui: action.payload?.performance?.ui[key],
              color: defaultChartColors[index%4]
              })
            })
          state.impactsData = {
            original: action.payload?.performance?.original ?
              Object.keys(action.payload.performance.original)
                .map((key) => ({ id: key, value: action.payload.performance.original[key]}))
            : [],
            potential: action.payload?.performance?.potential ?
              Object.keys(action.payload.performance.potential)
                .map((key) => ({ id: key, value: action.payload.performance.potential[key]}))
            : [],
            total: action.payload?.performance?.total ?
              Object.keys(action.payload.performance.total)
                .map((key) => ({ id: key, value: action.payload.performance.total[key]}))
            : [],
            lapse: action.payload?.performance?.lapse ?
              Object.keys(action.payload.performance.lapse)
                .map((key) => ({ id: key, value: action.payload.performance.lapse[key]}))
            : null,
            ui: action.payload?.performance?.ui ?
              Object.keys(action.payload.performance.ui)
                .map((key) => ({ id: key, value: action.payload.performance.ui[key]}))
            : null,
            keys: items,
            impact: (potential/original)*100,
            products,
          }
        },
        setDefaultProspectsColumns: (state, action) => {
          state.defaultProspectsColumns = action.payload
        },
        setSelectedPolicies: (state, action) => {
          state.selectedPolicies = action.payload
        },
        resetActiveTab: (state) => {
          state.activeTab = 'Impact on Premium'
        },
        resetData: (state) => {
            state = initialState
        },
        resetProjectsData: (state) => {
            state.groupByProject = initialState.groupByProject
            state.projectIds = initialState.projectIds
        },
        resetImpactsData: (state) => {
            state.groupByProject = initialState.groupByProject
            state.projectIds = initialState.projectIds
            state.impactsData = initialState.impactsData
        },
        resetFilters: (state) => {
            state.filters = {
                product: null,
                premium: null,
                face_amount: null,
                gender: null,
                age: null,
                story: null,
                mean_premium_term: null,
                face_amount_prediction: null,
                storyType: null,
                priority: null,
                libr_status: null,
                Duration_segment: null,
                MARKET_NAME_LAG: null,
                lapse: {
                    product: null,
                    premium: null,
                    face_amount_prediction: null,
                    gender: null,
                    age: null,
                    story: null,
                },
                ui: {
                    product: null,
                    premium: null,
                    face_amount: null,
                    gender: null,
                    age: null,
                    story: null,
                }
            }
            state.defaultProspectsColumns = localStorage.getItem("accountType") === "annuities" ? defaultColumnsAnnuities : defaultColumnsLife
        },
        resetLapseFilters: (state) => {
            state.filters = {
                ...state.filters,
                face_amount_prediction: null,
                mean_premium_term: null,
                storyType: null,
                story: null,
                priority: null,
                sensitivity: null,
            }
        },
        resetUIFilters: (state) => {
            state.filters = {
                ...state.filters,
                product: null,
                premium: null,
                face_amount: null,
                gender: null,
                age: null,
                cash_value: null,
                surrender_value: null,
                libr_payment: null,
                withdraw_activity: null,
                sub_activity: null,
                surrender_year_value: null,
                income_value: null,
                libr_status: null,
                contract_loss: null,
                income_lost: null,
                agent_status: null,
                state: null,
                res_state: null,
                Duration_segment: null,
                MARKET_NAME_LAG: null,
            }
        },
        resetQueryFilters: (state) => {
            state.queryFilters = {
              lapse: {},
              ui: {}
            }
        },
        resetProducts: (state) => {
          state.productsData = {
            products: [],
            productsDiff: [],
            current: [],
            newBuisnes: [],
            retention: [],
            total: [],
            prospects: 0,
            premium: 0,
            premium_annual: 0,
            policies: 0,
            potential: 0,
            period: 0,
            unpdated_at: "",
            policiesLapse: 0,
            policiesTotal: 0,
            potentialLapse: 0,
            total_count: 0,
            total_impact: 0,
            upside: 0,
            ui_runs: [],
            lapse_runs: [],
            cluster_runs: []
          }
        },
        setProductsIsLoading: (state, action) => {
          state.productsIsLoading = action.payload
        },
        setStoriesIsLoading: (state, action) => {
          state.storiesIsLoading = action.payload
        }    
    }
})

export const { setProducts, setOpportunities, setOpportunitiesBroker, setProspectsData, setProspectsDataAE, setProspectsList, setProspectsDataBroker, setAgeFilter, setGenderFilter, setStoryFilter, setActiveTab, setContractValueFilter, setSurrValueFilter, setSurrYearValueFilter, setIncomeAccFilter, setAgentStatusFilter, setLibrFilter, setWithdravalActivityFilter, setSubActivityFilter, setContractLossFilter, setIncomeLossFilter, setSensitivityFilter,
  setStoryTypeFilter, setProductFilter, setUIPremiumFilter, setCashValueFilter, setDurationFilter, setUIFaceAmountFilter, setLapsePremiumFilter, setLapseFaceAmountFilter, setQueryFilters, setGroupByProject, setProjectIds, setProspectsHeaders, setProspectsRuns, setOpportunityRuns, setImpactsData, 
  setStoriesIsLoading, setRunsMeta, setProspectsListAE, setPremiumTermFilter, setProductNameFilter, setDefaultProspectsColumns, setSelectedPolicies,
  resetActiveTab, resetData, resetProducts, resetFilters, resetUIFilters, resetLapseFilters, resetQueryFilters, resetProjectsData, resetImpactsData, setProductsIsLoading, setStorieIsLoadings, setProjectLabels, setStateFilter, setResStateFilter, setPriorityFilter, setLIBRStatusFilter } = BookSlice.actions

export const selectProductsData = (state) => state.Book.productsData
export const selectOpportunities = (state) => state.Book.opportunitiesData
export const selectProspectsData = (state) => state.Book.prospectsData
export const selectProspectsFilters = (state) => state.Book.filters
export const selectQueryFilters = (state) => state.Book.queryFilters
export const selectProductsIsLoading = (state) => state.Book.productsIsLoading
export const selectStoriesIsLoading = (state) => state.Book.storiesIsLoading
export const selectActiveTab = (state) => state.Book.activeTab
export const selectGroup = (state) => state.Book.groupByProject
export const selectIds = (state) => state.Book.projectIds
export const selectLabels = (state) => state.Book.projectLabels
export const selectHeaders = (state) => state.Book.prospectsHeaders
export const selectImpacts = (state) => state.Book.impactsData
export const selectRuns = (state) => state.Book.runsMeta
export const selectDefaultProspectsColumn = (state) => state.Book.defaultProspectsColumns
export const selectSelectedPolicies = (state) => state.Book.selectedPolicies




export default BookSlice.reducer