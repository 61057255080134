import React, { useState, useEffect } from "react";
import { CircularProgress, Divider, Grid, IconButton, MenuItem, Select, Typography } from "@material-ui/core";
import { ReactComponent as CloseIcon } from "../../../Assets/icon-close-alt.svg";
import { ReactComponent as PlusIcon } from "../../../Assets/icon-plus.svg";
import { ReactComponent as PersonIcon } from "../../../Assets/icon-identity.svg";
import { ReactComponent as LocationIcon } from '../../../Assets/icon-location.svg';
import { ReactComponent as BirthdayIcon } from '../../../Assets/icon-birthday.svg';
import { ReactComponent as ArrowUpIcon } from '../../../Assets/icon-arrow-up-alt.svg';
import { ReactComponent as ArrowUpLongIcon } from '../../../Assets/icon-arrow-up-long.svg';
import { getCountryCurrencySymbol, getLapseRiskBin, getLocalTime, getPriorityFromRank, getPtdDate, nFormatter } from "../../../global/helpers";
import useStyles from "./PolicyPopupBroker.styles";
import { useSelector } from "react-redux";
import PolicyPopupContainer from "./PolicyPopupContainer";
import { priorityIndex, renderValue } from "./constants";
import clsx from "clsx";
import RiskIndicator from "../../../Pages/BookAnalysis/PriorityIndicator/RiskIndicator";
import NotePopup from "./NotePopup/NotePopup";
import moment from "moment";
import { selectUserSubscriptions } from "../../../AppSlice";
import TypingProgress from "./TypingProgress/TypingProgress";
import StatusesApiService from "../../../Pages/StatusesConfig/StatusesApiService";
import cityTimezones from "city-timezones"
import PriorityTag from "../../../Pages/BookAnalysis/Prospects/PriorityTag/PriorityTag";

const ApiService = new StatusesApiService()

const PolicyPopupBroker = ({ 
    open, 
    onClose,
    policy,
    notes,
    stories,
    history,
    maxRank,
    onNotesAdded,
    onStatusChange,
    reset = () => {},
    onEdit,
    broker = false,
    anchor 
}) => {
    const classes = useStyles()
    const currency = getCountryCurrencySymbol()
    const subscriptions = useSelector(selectUserSubscriptions)
    const [ openNote, setOpenNote ] = useState(false)
    const [ expandNote, setExpandNote ] = useState(true)
    const [ statuses, setStatuses ] = useState([])
    const [ status, setStatus ] = useState("")

    const isAnnuities = localStorage.getItem("accountType") === "annuities"
    const isFreeSubcriptionPlan = !subscriptions.find(({ name }) => name === "basic")
    const timeZone = policy?.state && cityTimezones.cityMapping.filter(({ iso2, state_ansi }) => iso2 === "US" && state_ansi === policy["state"])
    const locaTime = timeZone?.length > 0 ? getLocalTime(new Date().getTime(), timeZone[0].timezone) : null
    policy && console.log(policy["recommend"], policy["recommend"] > 0);

    const getNoteDate = (dateString) => {
        return dateString ? getPtdDate(new Date(dateString).getTime(), "America/Los_Angeles") : ""
    }
   
    const handleOpenNotes = (e) => {
        e.stopPropagation()
        setOpenNote(true)
    }
    
    const handleCloseNotes = (success = false) => {
        if (success) {
            onNotesAdded(policy["id"])
            setExpandNote(true)
        }
        setOpenNote(false)
    }

    const handleChangeStatus = (e) => {
        ApiService._patchPolicyStatus(policy["id"], e.target.value,
            () => {
                setStatus(e.target.value)
                onStatusChange(policy["id"])
            }, (error) => console.log(error))
    }
    
    const handleClose = () => {
        onClose()
        setStatus("")
        if (broker && status && status !== policy?.status_name) {
            reset()
        }
    }

    useEffect(() => {
        if (policy?.status_name) {
            setStatus(policy["status_name"])
        }
    }, [policy])

    useEffect(() => {
        if (!statuses.length) {
            ApiService._getStatusesList((response) => {
                setStatuses(response.data.statuses)
            }, (error) => console.log(error))
        }
    }, [statuses])
    
    return (
        <PolicyPopupContainer
            open={open}
            onClose={handleClose}
            anchorEl={anchor}
            model={policy?.model_type}
            backdrop
            classes={classes}
            >
                {policy && 
                <Grid container className={classes.popupContentContainer}>
                    <Grid container justifyContent="space-between" alignItems="center" className={classes.closeIconContainer}>
                        <div>
                            <Typography>Client Insights</Typography>
                        </div>
                        <div className={classes.closeIconContent}>
                            <CloseIcon className={classes.closeIcon} onClick={handleClose}/>
                        </div>
                    </Grid>
                    <Grid container style={{ gap: 12, paddingTop: 42 }}>
                    <Grid container justifyContent="center" className={clsx(classes.detailsContainer, classes.detailsMid)}>
                        <Grid container direction="column" className={classes.detailsContent} style={{ gap: 24 }}>
                            <Grid container alignItems="center" style={{ gap: 12 }}>
                                <Grid item className={classes.genderIcon}>
                                </Grid>
                                <Grid item>
                                    <h1>{policy["client_name"] ? renderValue(policy["client_name"]) : "N/A"}</h1>
                                </Grid>
                                <Grid item className={classes.statusContainer}>
                                    <Select value={status} onChange={handleChangeStatus} placeholder="Select Status">
                                        {
                                            statuses.map((item) => (
                                                <MenuItem value={item.status} key={item.status}>{item.status}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Grid> 
                                <Grid container justifyContent="space-between" alignItems="center" style={{ gap: 12 }}>
                                    <Grid item className={classes.detailsItem}>
                                        <PersonIcon/>
                                        <Typography>
                                            {renderValue(policy["gender"])}
                                            ({renderValue(policy["age_at_issue"])})
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.detailsItem}>
                                        <LocationIcon/>
                                        <Typography>
                                            {renderValue(policy["address"])}
                                            ({locaTime && policy?.address ? locaTime : ""})
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.detailsItem}>
                                    <BirthdayIcon/>
                                        <Typography>
                                            {policy["dob"] !== "Invalid date" ? renderValue(policy["dob"]) : "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>                            
                            <Grid container>
                                <Divider style={{ width: "100%" }}/>
                            </Grid>

                            <Grid container className={classes.detailsAccordion}>
                                <Grid container>
                                    <Typography variant="h2">Details - Policy ID <span>{renderValue(policy["policy"])}</span></Typography>
                                </Grid>
                                <Grid container>
                                    <Grid container xs={6}>
                                        <p>Product</p>
                                    </Grid>
                                    <Grid container xs={6}>
                                        <p className="value">{renderValue(policy["product"])}</p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid container xs={6}>
                                        <p>Annual Amount</p>
                                    </Grid>
                                    <Grid container xs={6}>
                                        <p className="value">{policy["annualized_premium"] ? policy["annualized_premium"] > 9999 ? nFormatter(policy["annualized_premium"], 2, currency) : `$${Math.floor(policy["annualized_premium"])}` : "N/A"}</p>
                                    </Grid>
                                </Grid>
                                {broker ? <Grid container>
                                    <Grid container xs={6}>
                                        <p>Cash Value</p>
                                    </Grid>
                                    <Grid container xs={6}>
                                        <p className="value">{policy["cash_value"] !== undefined ? policy["cash_value"] > 9999 ? nFormatter(policy["cash_value"], 2, currency) : `$${Math.floor(policy["cash_value"])}` : "0/A"}</p>
                                    </Grid>
                                </Grid>
                                : <Grid container>
                                    <Grid container xs={6}>
                                        <p>Account Value</p>
                                    </Grid>
                                    <Grid container xs={6}>
                                        <p className="value">{policy["account_value"] ? policy["account_value"] > 9999 ? nFormatter(policy["account_value"], 2, currency) : `$${Math.floor(policy["account_value"])}` : "N/A"}</p>
                                    </Grid>
                                </Grid>
                                }
                                <Grid container>
                                    <Grid container xs={6}>
                                        <p>Issue Date</p>
                                    </Grid>
                                    <Grid container xs={6}>
                                        <p className="value">{renderValue(policy["issue_date"] ? moment(policy["issue_date"]).format('MM/DD/YYYY') : null)}</p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid container xs={6}>
                                        <p>Face Amount</p>
                                    </Grid>
                                    <Grid container xs={6}>
                                        <p className="value">{renderValue(policy["face_amount"] ? nFormatter(policy["face_amount"], 2, currency) : null)}</p>
                                    </Grid>
                                </Grid>
                            </Grid>    
                        </Grid>

                        {broker && <Grid container direction="column" className={classes.detailsContent} style={{ maxHeight: 210, overflowY: "auto", overflowX: "hidden"}}>
                            <Grid container className={classes.detailsAccordion}>
                                <Grid container>
                                    <Typography variant="h2">Status Log</Typography>
                                </Grid>
                                {history?.map(
                                    ({ created_at, updated_at, new_status_name, old_status_name }) => (
                                    <Grid container style={{ gap: 6 }}>
                                        <Grid container alignItems="flex-start" className={classes.brokerListItem}>
                                            <Grid container direction="column" style={{ maxWidth: 239 }}>
                                                <p className="description">Changed status to <span>“{new_status_name}”</span></p>
                                                <p className="time">{updated_at}</p>
                                                <Grid container justifyContent="center" style={{ paddingTop: 6 }}><ArrowUpLongIcon/></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="flex-start" className={classes.brokerListItem}>
                                            <Grid container direction="column" style={{ maxWidth: 239 }}>
                                                <p className="description">Changed status from <span>“{old_status_name}”</span></p>
                                                <p className="time">{created_at}</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    )
                                )}
                            </Grid>
                        </Grid>}
                    </Grid>
                    <Grid container direction="column" className={clsx(classes.detailsContainer, classes.detailsBig)}>
                        {!isAnnuities ? <Grid container justifyContent="flex-end" style={{ gap: 12 }}>
                            {!isNaN(policy["recommend"]) && <Grid item container className={clsx(classes.detailsContent, classes.premiumContent)}>
                                <Typography className={classes.title}>Suggested Premium</Typography>
                                <Grid item container justifyContent="space-between">
                                    <p className="current">{policy["current"] > 9999 ? nFormatter(policy["current"], 2, currency) : `${currency}${Math.floor(policy["current"])}`}</p>
                                    {policy["recommend"] && policy["recommend"] !== policy["current"] &&
                                    <p className={`recommend${policy["recommend"] > 0 ? " increase" : " decrease"}`}>
                                        {policy["recommend"] > 0 ? "+" : "-"}
                                        {policy["recommend"] > 9999 ?
                                            nFormatter(policy["recomnend"] > 0 ? policy["recommend"] : policy["recommend"]*-1, 2, currency)
                                            : `${currency}${Math.floor(policy["recommend"] > 0 ? policy["recommend"] : policy["recommend"]*-1)}`
                                        }
                                        <ArrowUpIcon/>
                                    </p>}
                                </Grid>
                            </Grid>}
                            {policy["annualized_premium_sensitivity_DML"] && <Grid item container className={clsx(classes.detailsContent, classes.premiumContent)}>
                                <Typography className={classes.title}>Premium Sensitivity</Typography>
                                <Grid item container justifyContent="space-between">
                                {priorityIndex[policy["annualized_premium_sensitivity_DML"]] && <PriorityTag 
                                    value={priorityIndex[policy["annualized_premium_sensitivity_DML"]]}
                                />}
                                </Grid>
                            </Grid>}
                            {policy["upsell_rank"] && maxRank && <Grid item container className={clsx(classes.detailsContent, classes.premiumContent)}>
                                <Typography className={classes.title}>Lapse Rate</Typography>
                                <Grid item container justifyContent="space-between">
                                    <PriorityTag 
                                    value={getPriorityFromRank(policy["upsell_rank"], maxRank)}
                                    />
                                </Grid>
                            </Grid>}
                        </Grid>
                        :
                        <Grid container style={{ gap: 12 }} justifyContent="flex-end">
                            <Grid item container className={clsx(classes.detailsContent, classes.premiumContent)}>
                                <Typography className={classes.title}>Lapse Rate</Typography>
                                {policy["lapse_rank_bin"] && <Grid item container justifyContent="space-between">
                                    <PriorityTag 
                                    value={policy["lapse_rank_bin"]}
                                    />
                                </Grid>}
                            </Grid>
                        </Grid>
                        }
                        <Grid container className={classes.detailsContent} style={{ gap: 20 }}>
                            <Grid container>
                                <Typography className={classes.title}>
                                    Gen AI Recommendations 
                                </Typography>
                            </Grid> 
                            {!stories && <Grid container className={classes.storyContent} style={{ display: "none" }}>
                                <Grid container alignItems="center" style={{ height: 18 }}><TypingProgress/></Grid> 
                            </Grid>}
                            {stories !== null && <Grid container className={classes.storyContent}>
                                {
                                    stories?.length ?
                                    stories?.map(({title, text}) =>  
                                    (<Grid container>
                                        <Typography className={classes.titleSecondary}>
                                            {title}:
                                        </Typography>
                                        <Typography className={classes.text}>{text}</Typography> 
                                    </Grid> )
                                    )
                                    : <Grid container justifyContent="center" alignContent="center" style={{height: 278}}>
                                        <CircularProgress style={{color: "#363ED3",width:48,height:48}}/>
                                    </Grid>  
                                }
                            </Grid>}
                        </Grid>

                    </Grid>
                    
                    <Grid container className={classes.detailsSmall} direction="column" style={{ gap: 12 }}>
                        <Grid container className={classes.detailsContent} style={{ padding: "14px 24px" }}>
                            {policy["story"] && <Grid container className={classes.storyItem} alignItems="center">
                                    <Grid container>
                                        <Typography className={classes.title}>Story</Typography>
                                    </Grid>
                                    <div>{renderValue(policy["story"])}</div>
                            </Grid>}
                        </Grid>

                        {broker && <Grid container className={classes.detailsContent} style={{ maxHeight: 451, overflowY: "auto", overflowX: "hidden"}}>   
                            <Grid container className={classes.detailsAccordion} style={{ gap: 12 }}>
                                <Grid container justifyContent="space-between">
                                    <Typography className={classes.title}>Notes</Typography>
                                    <IconButton className={classes.plusIcon} onClick={handleOpenNotes} disabled={isFreeSubcriptionPlan}>
                                        <PlusIcon/> Add Note
                                    </IconButton>
                                </Grid>
                                <Grid container style={{ gap: 14 }}>
                                    {
                                        notes.map(({ note, updated_at, id }) => (
                                            <Grid container alignItems="flex-start" className={classes.brokerListItem} key={`${policy["policy"]}-${id}`}>
                                                <Grid container direction="column" style={{ maxWidth: 204 }}>
                                                    <p className="description">{note}</p>
                                                    <p className="time">{getNoteDate(updated_at)}</p>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid> 
                        </Grid>}
                    </Grid>
                    </Grid>
                </Grid>
                }

                {policy && <NotePopup
                    open={openNote}
                    onClose={handleCloseNotes}
                    policyId={policy["id"]}
                    policyName={policy["client_name"] ? policy["client_name"] : ""}
                />}
        </PolicyPopupContainer>
    )
}

export default PolicyPopupBroker